<template>
  <div class="hours-eoh">
    <div class="hours-eoh__legend">
      <v-badge dot left inline>
        {{ $t('exceptionalHours.suggestedDate') }}
      </v-badge>
    </div>
    <div v-for="(hoursByYear, year) in groupByYear" :key="year" class="hours-eoh__section">
      <div class="hours-eoh__section__title">{{ year }}</div>
      <div class="hours-eoh__section__columns">
        <div
          class="hours-eoh__section__columns__column"
          v-for="(column, i) in columns(groupByMonth(hoursByYear))"
          :key="i"
        >
          <div v-for="(data, j) in column" :key="j" class="hours-eoh__section__columns__column__month">
            <div class="hours-eoh__section__columns__column__month__title">
              {{ $t(`common.months[${data[0] - 1}]`) }}
            </div>
            <div
              v-for="(hours, idx) in data[1]"
              :key="idx"
              class="hours-eoh__section__columns__column__month__slot"
              @click="editExceptionalHours(hours)"
            >
              <div
                class="hours-eoh__section__columns__column__month__slot__day"
                :class="{ 'hours-eoh__section__columns__column__month__slot__day--suggested': hours.fromPHW }"
              >
                <div class="hours-eoh__section__columns__column__month__slot__day__label" v-if="hours.fromPHW">
                  <v-badge dot left inline>
                    {{ hours.event.versions[locale.toUpperCase()] || hours.event.default }}
                  </v-badge>
                </div>
                <template v-if="hours.dateFrom === hours.dateTo || (hours.dateFrom && !hours.dateTo)">
                  {{ $t('common.date.on') }}
                  {{ date(hours.dateFrom) }}
                </template>
                <template v-else>
                  {{ $t('common.date.from') }} {{ date(hours.dateFrom) }} {{ $t('common.date.to') }}
                  {{ date(hours.dateTo) }}
                </template>
              </div>
              <div class="hours-eoh__section__columns__column__month__slot__time">
                <div v-if="hours.fromPHW" class="hours-eoh__section__columns__column__month__slot__time--no-data">
                  {{ $t('common.label.noData') }}
                </div>
                <div
                  v-else-if="!hours.openStatus"
                  class="hours-eoh__section__columns__column__month__slot__time--closed"
                >
                  {{ $t('common.label.closed') }}
                </div>
                <div
                  v-else-if="!hours.hours1 && !hours.hours2"
                  class="hours-eoh__section__columns__column__month__slot__time--open"
                >
                  {{ $t('common.label.open') }}
                </div>
                <div
                  v-else-if="hours.hours1 === '00:00-00:00'"
                  class="hours-eoh__section__columns__column__month__slot__time--open"
                >
                  {{ $t('common.label.h24') }}
                </div>
                <template v-else>
                  <div v-if="hours.hours1" class="hours-eoh__section__columns__column__month__slot__time--open">
                    {{ convertTime(hours.hours1.split('-')[0]) }} - {{ convertTime(hours.hours1.split('-')[1]) }}
                  </div>
                  <div v-if="hours.hours2" class="hours-eoh__section__columns__column__month__slot__time--open">
                    {{ convertTime(hours.hours2.split('-')[0]) }} - {{ convertTime(hours.hours2.split('-')[1]) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <screen-action
      :label="$t('locationsGmb.id.general.section.exceptionalOpeningHours.button.addSet')"
      icon="add_hours"
      :is-loading="isLoading"
      @click="editExceptionalHours()"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { formatedDate } from '@/utils/date.util'
import { convert24hto12h } from '@/utils/hours.util'
import { mdiPlus } from '@mdi/js'
import { unionBy, sortBy, groupBy } from 'lodash'
import ScreenAction from '@/components/Screen/Action.vue'

export default {
  name: 'HoursEoh',
  props: {
    exceptionalHours: {
      type: Array,
      required: true,
    },
    suggestedDays: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    amPm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ScreenAction,
  },
  data: () => ({
    icons: {
      mdiPlus,
    },
  }),
  computed: {
    groupByYear() {
      return groupBy(
        sortBy(unionBy(this.exceptionalHours, this.suggestedDays, 'dateFrom'), 'dateFrom'),
        item => item.dateFrom.split('-')[0]
      )
    },
  },
  methods: {
    getMonth(date) {
      return dayjs(date).get('M')
    },
    groupByMonth(values) {
      return groupBy(values, item => parseInt(item.dateFrom.split('-')[1]))
    },
    columns(months) {
      const array = Object.entries(months)
      const half = Math.ceil(array.length / 2)
      return [array.splice(0, half), array.splice(-half)]
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
    isSuggestedDate(hour) {
      return !!hour?.fromPHW
    },
    editExceptionalHours(hour) {
      this.$emit('editExceptionalHours', hour)
    },
    convertTime(time) {
      if (this.amPm) {
        return convert24hto12h(time)
      }
      return time
    },
  },
}
</script>

<style lang="scss" scoped>
.hours-eoh {
  padding: 0 0.5rem;
  width: 100%;

  @media (min-width: $screen-sm) {
    padding: 0 0.75rem;
  }

  &__legend {
    color: $grey-french;
  }

  &__section {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    &__title {
      font-size: 1rem;
      font-weight: bold;
    }

    &__columns {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;

      @media (min-width: $screen-sm) {
        grid-template-columns: 1fr 1fr;
      }

      &__column {
        &__month {
          margin-top: 1rem;

          &:first-child {
            margin-top: 0;
          }

          &__title {
            font-weight: 600;
          }

          &__slot {
            display: flex;
            justify-content: space-between;
            margin: 0 -0.5rem;
            padding: 0.5rem;

            &:hover {
              border-radius: 6px;
              background-color: var(--bg-color-hover);
              cursor: pointer;
            }

            &__day {
              &--suggested {
                color: $grey-french;
              }

              &__label {
                margin-left: -0.25rem;
                font-weight: 500;
              }
            }

            &__time {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              align-self: center;
              color: $black;

              &--no-data {
                color: $grey-french;
                font-style: italic;
              }

              &--open {
                color: map-get($generic-color-variants, 'success');
              }

              &--closed {
                color: map-get($generic-color-variants, 'error');
              }
            }
          }
        }
      }
    }
  }
}
</style>
