<template>
  <v-card class="locationGmb-googleUpdates-itemAdmin" elevation="0">
    <v-container fluid>
      <v-row class="tw-mb-6 tw-items-center">
        <v-col cols="5" class="locationGmb-googleUpdates-itemAdmin__block">
          <v-row class="tw-font-semibold tw-items-end">
            <div>
              <v-icon class="tw-mr-1" size="20px" :color="this.$vuetify.theme.dark ? 'white' : 'black'">{{
                getIconField
              }}</v-icon>
              {{ item.field }}
            </div>
          </v-row>
          <v-row class="locationGmb-googleUpdates-itemAdmin__block__date">
            {{
              $t('locationsGmb.id.googleUpdates.date', { date: formatedDate(item.created_at, 'L', this.$i18n.locale) })
            }}
          </v-row>
        </v-col>
        <v-col cols="2"> </v-col>
        <v-col cols="5" class="locationGmb-googleUpdates-itemAdmin__block">
          <v-row>
            <ui-button
              class="locationGmb-googleUpdates-itemAdmin__button locationGmb-googleUpdates-itemAdmin__button--reject"
              medium
              :vuetify-icon="true"
              :icon="icons.mdiClose"
              button="primary"
              variant="error"
              :label="$t('locationsGmb.id.googleUpdates.button.delete')"
              @click="$emit('updateStatus', { status: -1, id: item.id })"
            />
            <ui-button
              class="locationGmb-googleUpdates-itemAdmin__button locationGmb-googleUpdates-itemAdmin__button--accept"
              medium
              :vuetify-icon="true"
              :icon="icons.mdiCheck"
              button="primary"
              variant="success"
              :label="$t('locationsGmb.id.googleUpdates.button.accept')"
              @click="$emit('updateStatus', { status: 1, id: item.id })"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="5"
          class="locationGmb-googleUpdates-itemAdmin__block locationGmb-googleUpdates-itemAdmin__block--border"
        >
          <v-row class="locationGmb-googleUpdates-itemAdmin__block__content">
            <div class="tw-mb-2">
              {{ $t('locationsGmb.id.googleUpdates.fields.info') }}
            </div>
            <v-dialog class="dialog" scrollable overlay-opacity="0.2" max-width="50%" max-height="50px">
              <template v-slot:activator="{ on, attrs }">
                <code class="dialog__code" v-bind="attrs" v-on="on">
                  <span class="dialog__code__value">{{ itemValueFormat(item.old_value) }}</span>
                </code>
              </template>
              <v-card class="dialog__card">
                <v-card-title class="dialog__card__title">
                  <span class="tw-capitalize">{{ item.field }}</span>
                  <v-icon color="white" class="tw-ml-auto" @click="copyJson(item.old_value)">
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                </v-card-title>
                <v-card-text class="tw-py-4 tw-px-8">
                  <pre
                    v-if="isValueObject(item.old_value)"
                    v-html="JSON.stringify(JSON.parse(item.old_value), null, 4)"
                    class="tw-whitespace-pre-wrap"
                  />
                  <span v-else>{{ itemValueFormat(item.old_value) }}</span>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
        <v-col class="tw-flex tw-justify-center tw-items-center" cols="2">
          <v-icon size="30px" color="#397cff">{{ icons.mdiArrowRightBold }}</v-icon>
        </v-col>
        <v-col
          cols="5"
          class="locationGmb-googleUpdates-itemAdmin__block locationGmb-googleUpdates-itemAdmin__block--active"
          :class="{ 'locationGmb-googleUpdates-itemAdmin__block--border': !this.$vuetify.theme.dark }"
        >
          <v-row class="locationGmb-googleUpdates-itemAdmin__block__content">
            <div class="tw-mb-2">
              {{ $t('locationsGmb.id.googleUpdates.fields.update') }}
            </div>
            <v-dialog class="dialog" scrollable overlay-opacity="0.2" max-width="50%" max-height="50px">
              <template v-slot:activator="{ on, attrs }">
                <code class="dialog__code dialog__code--light" v-bind="attrs" v-on="on">
                  <span class="dialog__code__value">{{ itemValueFormat(item.new_value) }}</span>
                </code>
              </template>
              <v-card class="dialog__card">
                <v-card-title class="dialog__card__title">
                  <span class="tw-capitalize">{{ item.field }}</span>
                  <v-icon @click="copyJson(item.new_value)" color="white" class="tw-ml-auto">
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                </v-card-title>
                <v-card-text class="tw-py-4 tw-px-8">
                  <pre v-html="highlightStringDiff(item.new_value, item.old_value)" class="dialog__card__text" />
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider color="#f7f8fb" width="95%" class="tw-mx-auto tw-my-8" />
  </v-card>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import { compareString } from '@/utils/string.util'
import { copyToClipboard } from '@/utils/extractor.util'
import { notification } from '@/utils/notification.util'
import {
  mdiArrowRightBold,
  mdiPhone,
  mdiClose,
  mdiCheck,
  mdiWeb,
  mdiTranslate,
  mdiLinkVariant,
  mdiStoreMarker,
  mdiCardAccountDetails,
  mdiStoreClock,
  mdiClockAlert,
  mdiStickerText,
  mdiCalendar,
  mdiSubtitlesOutline,
  mdiTag,
  mdiDomain,
  mdiMapMarker,
  mdiContentCopy,
} from '@mdi/js'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'LocationGmbGoogleUpdatesItemAdmin',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    UiButton,
  },
  data() {
    return {
      compareString,
      formatedDate,
      icons: {
        mdiArrowRightBold,
        mdiClose,
        mdiCheck,
        mdiContentCopy,
      },
    }
  },
  computed: {
    getIconField() {
      switch (this.item.field) {
        case 'title':
          return mdiSubtitlesOutline
        case 'categories':
          return mdiTag
        case 'storeCode':
          return mdiDomain
        case 'storefrontAddress':
          return mdiMapMarker
        case 'metadata':
          return mdiWeb
        case 'phoneNumbers':
          return mdiPhone
        case 'languageCode':
          return mdiTranslate
        case 'websiteUri':
          return mdiLinkVariant
        case 'latlng':
          return mdiStoreMarker
        case 'profile':
          return mdiCardAccountDetails
        case 'regularHours':
          return mdiStoreClock
        case 'specialHours':
          return mdiClockAlert
        case 'openInfo':
          return mdiCalendar
        default:
          return mdiStickerText
      }
    },
  },
  methods: {
    highlightStringDiff(source, reference) {
      try {
        return compareString(
          JSON.stringify(JSON.parse(source), null, 4),
          JSON.stringify(JSON.parse(reference), null, 4)
        )
      } catch (error) {
        const stringCompare = compareString(JSON.stringify(source, null, 4), JSON.stringify(reference, null, 4))
        return stringCompare !== '"<mark>"' ? stringCompare : this.$t('locationsGmb.id.googleUpdates.fields.empty')
      }
    },
    copyJson(link) {
      copyToClipboard(link)
      notification({
        text: this.$t('common.notification.copyJson'),
        type: 'success',
      })
    },
    itemValueFormat(value) {
      let valueFormat = value.replace(/\\\//g, '/')
      if (value) {
        try {
          valueFormat = JSON.parse(value)
        } catch (err) {
          // console.log(err)
        }
        return valueFormat
      }
      return this.$t('locationsGmb.id.googleUpdates.fields.empty')
    },
    isValueObject(value) {
      try {
        const parsedObj = JSON.parse(value)
        if (typeof parsedObj === 'object') {
          return true
        }
        return false
      } catch (err) {
        return false
      }
    },
  },
}
</script>

<style lang="scss">
.dialog__card__text {
  mark {
    background-color: $orange-tree-poppy;
    padding: 1.1px 0;
    color: white;
  }
}
</style>

<style scoped lang="scss">
.locationGmb-googleUpdates-itemAdmin {
  width: 90%;
  &__button {
    @apply tw-mt-4 tw-text-xs;

    width: 100%;
    color: $blue-dodger !important;

    @media (min-width: $screen-sm) {
      width: 45%;
    }

    &--accept {
      margin-left: auto;
      color: $green-mantis !important;

      &:hover {
        color: $green-mantis !important;
      }
    }
    &--reject {
      color: $red-pomegranate !important;
      &:hover {
        color: $red-pomegranate !important;
      }
    }
  }

  &__block {
    @apply tw-p-4 tw-rounded-lg;

    &--active {
      background-color: $blue-dodger;
      color: $white;
    }

    &--border {
      border: 0.5px solid $grey-athens;
    }

    &__content {
      @apply tw-flex-col tw-flex-nowrap tw-h-full;

      margin: auto 0;
    }

    &__date {
      @apply tw-text-xs;

      margin-left: 16px;
    }
  }
}

.dialog {
  &__code {
    @apply tw-py-2 tw-px-4 tw-h-full;

    &--light {
      background-color: $grey-very-light !important;
      color: $dark-grey !important;
    }

    &__value {
      @include text-ellipsis(4, 12px);

      line-height: 1.15;
    }
  }

  &__card {
    max-height: 500px;
    &__title {
      background-color: $blue-dodger;
      color: white;
      font-weight: $font-weight-800;
    }
    &__text {
      @apply tw-whitespace-pre-wrap;
    }
  }
}
</style>
