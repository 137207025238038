<template>
  <screen-grid class="location-gmb-attribut">
    <screen-card
      :title="$t('locationsGmb.id.attribut.section.serviceareas.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :key="'attribut-autocomplete'"
          :title="$t('locationsGmb.id.attribut.section.serviceareas.autocomplete.title')"
          icon="geoloc"
          :has-data="hasServiceArea"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editGmbAutocomplete(currentLocation.serviceArea)"
        >
          <template v-slot:data>
            <div v-for="(place, idx) in currentLocation.serviceArea.places.placeInfos" :key="idx">
              {{ place.placeName }}
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>

    <screen-card
      :title="$t('locationsGmb.id.attribut.section.attributs.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          v-for="refs in groupedRefAttributesSortedByContent"
          :key="`attribut-${refs[0].label}`"
          :title="refs[0].label"
          icon="attribut"
          :has-data="true"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editAttribute(refs[0].label)"
        >
          <template v-slot:data>
            <template v-if="typeof groupedAttributes[refs[0].label] === 'object'">
              <div v-for="(item, idx) in groupedAttributes[refs[0].label]" :key="`item-${idx}`">
                {{ item.label }}
              </div>
            </template>
            <div v-else></div>
          </template>
        </screen-block>
      </template>
    </screen-card>

    <screen-card
      :title="$t('locationsGmb.id.attribut.section.services.title')"
      ratio="1-1"
      :overflow-hidden="true"
      :display="disabledCanModifyServiceList ? 'column' : 'grid'"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <div v-if="disabledCanModifyServiceList">
          {{ $t('locationsGmb.id.attribut.section.services.unauthorized') }}
        </div>
        <template v-if="!disabledCanModifyServiceList">
          <screen-block
            v-for="(category, idx) in allCategorys"
            :key="`category-${idx}`"
            :title="category.displayName"
            icon="text"
            :icon-style="categoryHasServices(category) ? '' : 'color: grey;'"
            :has-data="categoryHasServices(category)"
            ratio="1-3"
            :is-loading="isLoading"
            :is-editable="true"
            @click="editCategory(category.categoryId || category.name)"
          >
            <template v-slot:data>
              <div v-for="(item, j) in category.services" :key="`item-${j}`">
                {{ item.label.displayName }}
              </div>
            </template>
          </screen-block>
        </template>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

export default {
  name: 'LocationGMBAttribut',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    allCategorys: {
      type: Array,
      required: true,
    },
  },
  computed: {
    disabledCanModifyServiceList() {
      return this.currentLocation?.metadata?.canModifyServiceList === false
    },
    groupedCategory() {
      return groupBy(this.allCategorys, 'categoryId')
    },
    groupedAttributes() {
      return groupBy(this.currentLocation.attributes, 'group')
    },
    groupedRefAttributes() {
      if (!this.currentLocation?.refAttributes || this.currentLocation?.refAttributes.length <= 0) {
        return []
      }
      return groupBy(
        this.currentLocation.refAttributes.map(refAttr => {
          return {
            ...refAttr,
            name: refAttr.parent,
          }
        }),
        'label'
      )
    },
    groupedRefAttributesSortedByContent() {
      return orderBy(Object.values(this.groupedRefAttributes), [
        refs => (this.groupedAttributes[refs[0].label] ? -1 : 1),
        '0.label',
      ])
    },
    hasServiceArea() {
      return this.currentLocation.serviceArea?.places?.placeInfos.length > 0
    },
  },
  methods: {
    categoryHasServices(category) {
      return !!category?.services && category.services.length > 0
    },
    editCategory(key) {
      this.$emit('editCategory', this.allCategorys.find(item => item.categoryId === key || item.name === key) || [])
    },
    editAttribute(key) {
      this.$emit('editAttribute', { attribut: this.groupedAttributes[key] || [], refs: this.groupedRefAttributes[key] })
    },
    editGmbAutocomplete(serviceArea) {
      this.$emit('editGmbAutocomplete', {
        serviceArea,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.test {
  color: $grey-heather;
}
</style>
