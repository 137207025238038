<template>
  <screen-grid class="location-gmb-photo">
    <screen-card
      :title="$t('locationsGmb.id.photos.section.owner.title')"
      ratio="1-2"
      :overflow-hidden="true"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <div
          class="location-gmb-photo__grid"
          v-if="currentLocationMedias.owner && currentLocationMedias.owner.length > 0"
        >
          <screen-flux
            v-for="(photo, idx) in currentLocationMedias.owner"
            :key="`photo-owner-${idx}`"
            :locale="$i18n.locale"
            :grid-flux="true"
            :picture-mode="true"
            icon="delete"
            :picture="photo.thumbnailGtr"
            :title="
              $t('locationsGmb.id.photos.section.owner.flux.title', {
                type: capitalizeString(photo.locationAssociation.category),
              })
            "
            :create-time="photo.createTime"
            :number-views="photo.insights.viewCount"
            :size="`${photo.dimensions.widthPixels}x${photo.dimensions.heightPixels}`"
            @click="showPreview(photo, 'owner')"
            @iconClick="deletePhoto(photo)"
          />
          <div
            class="location-gmb-photo__grid__wrapper location-gmb-photo__grid__wrapper-action"
            :class="{ 'location-gmb-photo__grid__wrapper-action--dark': $vuetify.theme.dark }"
          >
            <ui-button
              :label="$t('locationsGmb.id.photos.section.owner.addPhoto')"
              button="primary"
              variant="data"
              icon="add_photo"
              @click="addPhoto({ category: 'owner' })"
            />
          </div>
        </div>
        <div class="location-gmb-photo__no-data" v-else>
          <div class="location-gmb-photo__no-data__icon backoffice-icons">photo</div>
          <div class="location-gmb-photo__no-data__title">
            {{ $t('locationsGmb.id.photos.noData.title') }}
          </div>
          <div class="location-gmb-photo__no-data__description">
            {{ $t('locationsGmb.id.photos.noData.description.owner') }}
          </div>
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locationsGmb.id.photos.section.customer.title')"
      ratio="1-2"
      :overflow-hidden="true"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <div
          class="location-gmb-photo__grid"
          v-if="currentLocationMedias.customer && currentLocationMedias.customer.length > 0"
        >
          <screen-flux
            v-for="(photo, idx) in currentLocationMedias.customer"
            :key="`photo-customer--${idx}`"
            :locale="$i18n.locale"
            :grid-flux="true"
            :picture-mode="true"
            icon="flag"
            :href="photo.attribution.takedownUrl"
            :picture="photo.thumbnailGtr"
            :title="$t('locationsGmb.id.photos.section.customer.flux.title', { name: photo.attribution.profileName })"
            :create-time="photo.createTime"
            :number-views="photo.insights.viewCount"
            :size="`${photo.dimensions.widthPixels}x${photo.dimensions.heightPixels}`"
            @click="showPreview(photo, 'customer')"
            @iconClick="flagClick(photo)"
          />
        </div>
        <div class="location-gmb-photo__no-data" v-else>
          <div class="location-gmb-photo__no-data__icon backoffice-icons">photo</div>
          <div class="location-gmb-photo__no-data__title">
            {{ $t('locationsGmb.id.photos.noData.title') }}
          </div>
          <div class="location-gmb-photo__no-data__description">
            {{ $t('locationsGmb.id.photos.noData.description.customer') }}
          </div>
        </div>
      </template>
    </screen-card>
    <screen-card ratio="1-1" :overflow-hidden="true" :is-loading="isLoading" :is-relative="true">
      <template v-slot:body>
        <ui-button
          class="location-gmb-photo__tabs-navigation location-gmb-photo__tabs-navigation--left"
          variant="data"
          button="secondary"
          :label="$t('locationsGmb.id.photos.section.categories.button.left')"
          icon="chevron_left"
          :icon-only="true"
          @click="moveTabsToLeft"
        />

        <ui-button
          class="location-gmb-photo__tabs-navigation location-gmb-photo__tabs-navigation--right"
          variant="data"
          button="secondary"
          :label="$t('locationsGmb.id.photos.section.categories.button.right')"
          icon="chevron_right"
          :icon-only="true"
          @click="moveTabsToRight"
        />
        <div class="location-gmb-photo__tabs" :style="style" ref="photoTabs">
          <div
            class="location-gmb-photo__tabs__tab"
            :class="{ 'location-gmb-photo__tabs__tab--selected': currentCategory === category }"
            v-for="(category, idx) in photoCategories"
            :key="`tab-category-${idx}`"
            @click="setCurrentCategory(category)"
          >
            {{ $t(`locationsGmb.id.photos.section.categories.${category}`) }}
          </div>
        </div>
        <div
          class="location-gmb-photo__container"
          :class="{ 'location-gmb-photo__container--no-data': mediaByCategory.length === 0 }"
        >
          <div class="location-gmb-photo__container__grid" v-if="mediaByCategory.length > 0">
            <div
              class="location-gmb-photo__container__grid__wrapper"
              v-for="(photo, idx) in mediaByCategory"
              :key="`photo-by-category-${idx}`"
            >
              <img
                class="location-gmb-photo__container__grid__wrapper__photo"
                :src="photo.thumbnailGtr"
                @click="showPreview(photo, 'owner')"
              />
              <div class="location-gmb-photo__container__grid__wrapper__info">
                <div class="location-gmb-photo__container__grid__wrapper__info__stat" v-if="photo.insights.viewCount">
                  <span class="location-gmb-photo__container__grid__wrapper__info__stat__icon backoffice-icons"
                    >visibility</span
                  >
                  {{ photo.insights.viewCount }}
                </div>
                <ui-button
                  class="location-gmb-photo__container__grid__wrapper__info__action"
                  button="secondary"
                  :label="$t('modal.gmbPhoto.button.delete.label')"
                  @click="deletePhoto(photo)"
                  icon="delete"
                  :icon-only="true"
                />
              </div>
            </div>
            <div
              class="location-gmb-photo__container__grid__wrapper-action"
              :class="{ 'location-gmb-photo__container__grid__wrapper-action--dark': $vuetify.theme.dark }"
            >
              <ui-button
                :label="$t('locationsGmb.id.photos.section.owner.addPhoto')"
                button="primary"
                variant="data"
                icon="add_photo"
                @click="addPhoto({ category: currentCategory })"
                :disabled="checkCategory"
              />
            </div>
          </div>
          <div class="location-gmb-photo__no-data" v-else>
            <div class="location-gmb-photo__no-data__icon backoffice-icons">photo</div>
            <div class="location-gmb-photo__no-data__title">
              {{ $t('locationsGmb.id.photos.noData.title') }}
            </div>
            <div class="location-gmb-photo__no-data__description">
              {{ $t('locationsGmb.id.photos.noData.description.category') }}
            </div>
            <div class="location-gmb-photo__no-data__add-picture">
              <ui-button
                :label="$t('locationsGmb.id.photos.section.owner.addPhoto')"
                button="primary"
                variant="data"
                icon="add_photo"
                @click="addPhoto({ category: currentCategory })"
              />
            </div>
          </div>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenFlux from '@/components/Screen/Flux.vue'
import UiButton from '@/components/UI/Button.vue'
import { productColor } from '@/config/productColor.config'
import sortBy from 'lodash/sortBy'
import { capitalizeString } from '@/utils/utilities.util'

const SCROLL_STEP = 100

export default {
  name: 'LocationPhoto',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenFlux,
    UiButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentLocationMedias: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      capitalizeString,
      photoCategories: [
        'COVER',
        'LOGO',
        'EXTERIOR',
        'INTERIOR',
        'PROFILE',
        'PRODUCT',
        'MENU',
        'FOOD_AND_DRINK',
        'ROOMS',
        'TEAMS',
        'AT_WORK',
        'COMMON_AREA',
        'ADDITIONAL',
        'CATEGORY_UNSPECIFIED',
      ],
      currentCategory: 'COVER',
      isError: false,
      admins: [],
    }
  },
  computed: {
    checkCategory() {
      return ['COVER', 'PROFILE'].includes(this.currentCategory) && this.mediaByCategory.length < 2
    },
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
    mediaByCategory() {
      const sortedPhotos = sortBy(this.currentLocationMedias.owner, 'locationAssociation.category')
      return sortedPhotos.filter(photo => photo.locationAssociation.category === this.currentCategory)
    },
  },
  methods: {
    setCurrentCategory(category) {
      this.currentCategory = category
    },
    deletePhoto(photo) {
      this.$emit('deletePhoto', photo)
    },
    addPhoto({ category }) {
      this.$emit('addPhoto', { category })
    },
    moveTabsToLeft() {
      let sl = this.$refs.photoTabs.scrollLeft

      if (sl - SCROLL_STEP <= 0) {
        this.$refs.photoTabs.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
      } else {
        this.$refs.photoTabs.scrollTo({
          left: sl - SCROLL_STEP,
          behavior: 'smooth',
        })
      }
    },
    moveTabsToRight() {
      let sl = this.$refs.photoTabs.scrollLeft
      let cw = this.$refs.photoTabs.scrollWidth

      if (sl + SCROLL_STEP >= cw) {
        this.$refs.photoTabs.scrollTo({
          left: cw,
          behavior: 'smooth',
        })
      } else {
        this.$refs.photoTabs.scrollTo({
          left: sl + SCROLL_STEP,
          behavior: 'smooth',
        })
      }
    },
    showPreview(photo, type) {
      this.$emit('showPreview', { photo, type })
    },
    flagClick(photo) {
      this.$emit('flagClick', photo.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.location-gmb-photo {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gutter-mobile / 2;

    @media (min-width: $screen-sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gutter-tablet / 2;
    }

    &__card {
      display: grid;
      position: relative;
      grid-template-rows: 1fr, auto;
      transition: background-color $transition-duration-very-fast $transition-effect-default;
      cursor: pointer;

      &--dark {
        &:hover {
          background-color: $black-baltic-sea;
        }
      }

      &--light {
        &:hover {
          background-color: rgb(236, 236, 236);
        }
      }

      &:hover {
        /* stylelint-disable no-descending-specificity */
        .location-gmb-photo__grid__card__photo {
          transform: scale(0.95);
        }
      }

      &__photo {
        transition: all $transition-duration-fast $transition-effect-default;
        width: 100%;
      }

      &__info {
        margin-top: auto;
        padding: 4px;

        &__category {
          margin-bottom: 4px;
          font-size: $font-size-sm;
        }

        &__stat {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &--reverse {
            flex-direction: row-reverse;
          }

          &__view {
            display: flex;
            align-items: center;

            &__icon {
              margin-right: 8px;
            }
          }
        }

        &__action {
          width: 32px;
          height: 32px;
          color: $generic-color-text-alt;
        }
      }
    }

    &__wrapper {
      position: relative;

      &-action {
        display: flex;
        grid-column: 1 / -1;
        align-items: center;
        justify-content: center;
        background-color: $white-alabaster;

        &--dark {
          background-color: $interface-dark-grade-1-color;
        }
      }

      &__category {
        text-align: center;
        font-weight: 600;
      }

      &__photo {
        max-width: 100%;

        &:hover {
          cursor: pointer;
        }
      }

      &__info {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        padding: 4px;

        &__stat {
          display: flex;
          align-items: center;
          color: $generic-color-text-alt;

          &__icon {
            margin-right: 8px;
          }
        }

        &__action {
          width: 32px;
          height: 32px;
          color: $generic-color-text-alt;
        }
      }
    }
  }

  &__tabs-navigation {
    display: none;
    position: absolute;
    top: 2px;
    z-index: 1;

    @media (min-width: $screen-md) {
      display: inline-flex;
    }

    &--left {
      left: 2px;
    }

    &--right {
      right: 2px;
    }
  }

  &__tabs {
    display: flex;
    position: relative;
    align-items: center;
    margin: -$gutter-mobile;
    height: 48px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    @media (min-width: $screen-sm) {
      margin: -$gutter-tablet;
    }

    @media (min-width: $screen-md) {
      margin: (-$gutter-tablet) 22px (-$gutter-tablet) 22px;
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop) 16px (-$gutter-desktop) 16px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__tab {
      display: inline-flex;
      align-items: center;
      border-bottom: 4px solid transparent;
      padding: 0 $gutter-mobile;
      height: 48px;

      @media (min-width: $screen-sm) {
        padding: 0 $gutter-tablet;
      }

      &:hover {
        cursor: pointer;
      }

      &--selected {
        border-bottom: 4px solid;
        color: var(--product-color);
      }
    }
  }

  &__container {
    margin-top: $gutter-mobile;
    padding-top: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      padding-top: $gutter-tablet;
    }

    &--no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gutter-mobile / 2;

      @media (min-width: $screen-sm) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $gutter-tablet / 2;
      }

      @media (min-width: $screen-md) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: $gutter-tablet / 2;
      }

      @media (min-width: $screen-xl) {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: $gutter-tablet / 2;
      }

      &__wrapper {
        position: relative;

        &-action {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white-alabaster;
          padding: 0 $gutter-mobile;
          &--dark {
            background-color: $interface-dark-grade-1-color;
          }
        }

        &__photo {
          max-width: 100%;

          &:hover {
            cursor: pointer;
          }
        }

        &__info {
          display: flex;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          align-items: center;
          justify-content: space-between;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
          padding: 4px;

          &__stat {
            display: flex;
            align-items: center;
            color: $generic-color-text-alt;

            &__icon {
              margin-right: 8px;
            }
          }

          &__action {
            width: 32px;
            height: 32px;
            color: $generic-color-text-alt;
          }
        }
      }
    }
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__add-picture {
      margin-top: 1rem;
    }

    &__icon {
      margin-bottom: 8px;
      color: var(--text-color-alt);
      font-size: 4rem;
    }

    &__title {
      margin-bottom: 8px;
      font-weight: 600;
    }

    &__description {
      color: var(--text-color-alt);
    }
  }
}
</style>
