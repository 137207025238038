var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',{staticClass:"location-gmb-extra"},[_c('screen-card',{attrs:{"title":_vm.$t('locationsGmb.id.extras.section.extraData.title'),"ratio":"1-1","overflow-hidden":true,"display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('screen-block',{attrs:{"title":_vm.$t('locationsGmb.id.extras.section.extraData.label.googleAdsPhone'),"icon":"phone","has-data":_vm.hasGoogleAdsPhone,"data":_vm.hasGoogleAdsPhone ? _vm.currentLocation.adWordsLocationExtensions.adPhone : '',"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSimpleField('adWordsLocationExtensions.adPhone', 'text', 'input')}}}),_c('screen-block',{attrs:{"title":_vm.$t('locationsGmb.id.extras.section.extraData.label.languageCode'),"icon":"language","has-data":typeof _vm.currentLocation.languageCode === 'string',"data":_vm.currentLocation.languageCode,"ratio":"1-3","is-loading":_vm.isLoading,"is-editable":false}}),_c('screen-block',{attrs:{"title":_vm.$t('locationsGmb.id.extras.section.extraData.label.locations'),"icon":"article","has-data":_vm.currentLocationMapping.length > 0,"ratio":"1-3","is-loading":_vm.isLoading,"is-editable":true},on:{"click":function($event){return _vm.editSimpleField(
            'locationsMapping',
            'text',
            'list',
            false,
            null,
            _vm.locationsSearch,
            {
              label: 'name',
              trackBy: 'id',
            },
            true,
            false
          )}},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('div',{staticClass:"location-gmb-extra__locations"},_vm._l((_vm.currentLocationMapping),function(location,idx){return _c('span',{key:idx,staticClass:"location-gmb-extra__locations__item"},[_vm._v(" "+_vm._s(location.name)+" ")])}),0)]},proxy:true}])})]},proxy:true}])}),_c('screen-card',{attrs:{"title":_vm.$t('locationsGmb.id.extras.section.locationIds.title'),"ratio":"1-1","overflow-hidden":true,"display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('screen-block',{attrs:{"title":_vm.$t('locationsGmb.id.extras.section.locationIds.label.placeId'),"icon":"attribut","has-data":(_vm.hasLocationKey && typeof _vm.currentLocationPlaceId === 'string') || false,"data":_vm.currentLocationPlaceId,"ratio":"1-1","is-loading":_vm.isLoading,"is-editable":false}})]},proxy:true}])}),_vm._l((_vm.currentLocation.metadata),function(value,key){return _c('screen-card',{key:key,attrs:{"align":"center","ratio":"1-3"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('screen-block',{class:{
          'location-gmb-extra__status--active': value,
          'location-gmb-extra__status--inactive': !value,
        },attrs:{"title":_vm.$t(("locationsGmb.id.extras.section.metadata.label." + key)),"align":"center","has-data":true,"is-loading":_vm.isLoading,"is-editable":false},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.isLoading)?_c('skeleton-icon',{attrs:{"margin-right":"16px","width":"36px","height":"36px"}}):_c('div',{staticClass:"location-gmb-extra__status__icon backoffice-icons"},[_vm._v("check")])]},proxy:true},{key:"data",fn:function(){return [(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"18px","margin-top":"2px","width":"100px"}}):_c('div',{staticClass:"location-gmb-extra__status__state",class:{ 'location-gmb-extra__status__state--row': _vm.checkIsLink(value) }},[(value)?[_vm._v(" "+_vm._s(_vm.$t('common.button.yes'))+" "),(typeof value === 'string')?_c('div',[(_vm.checkIsLink(value))?_c('v-btn',{staticClass:"location-gmb-extra__status__state__link",attrs:{"icon":"","href":value,"target":"_blank"}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew))])],1):_c('div',{staticClass:"location-gmb-extra__status__state__text"},[_vm._v(" "+_vm._s(value)+" ")])],1):_vm._e()]:[_vm._v(" "+_vm._s(_vm.$t('common.button.no'))+" ")]],2)]},proxy:true}],null,true)})]},proxy:true}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }