<template>
  <v-card class="locationGmb-googleUpdates-itemAttribut" elevation="0">
    <v-container fluid>
      <v-card-subtitle class="locationGmb-googleUpdates-itemAttribut__subtitle">
        {{ label }}
      </v-card-subtitle>
      <v-fade-transition group>
        <location-gmb-google-updates-item-attribut-child
          v-for="(attribut, index) in items"
          :key="`attribut ${attribut.id}`"
          :attribut="clone(attribut)"
          :index="index"
          @updateStatus="payload => $emit('updateStatus', { ...payload })"
        />
      </v-fade-transition>
    </v-container>
    <v-divider color="#f7f8fb" width="90%" class="tw-mx-auto" />
  </v-card>
</template>

<script>
import { clone } from '@/utils/utilities.util'
import LocationGmbGoogleUpdatesItemAttributChild from '@/components/LocationGmb/GoogleUpdates/itemAttributChild'

export default {
  name: 'LocationGmbGoogleUpdatesItemAttribut',
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    LocationGmbGoogleUpdatesItemAttributChild,
  },
  data() {
    return {
      clone,
    }
  },
}
</script>

<style lang="scss">
.locationGmb-googleUpdates-itemAttribut {
  .radios {
    & .v-label--active {
      top: 5px;
    }
    & .v-select__selections {
      grid-gap: 4px;
    }
    & label {
      font-size: $font-size-sm;
    }
  }
}
</style>
<style lang="scss" scoped>
.locationGmb-googleUpdates-itemAttribut {
  width: 90%;

  &__subtitle {
    @apply tw-font-medium tw-pl-0;

    color: $blue-dodger;
  }
}
</style>
