<template>
  <v-card-text class="tw-py-0">
    <div class="locationGmb-googleUpdates-itemAttribut-child">
      <span class="locationGmb-googleUpdates-itemAttribut-child__label">{{ attribut.displayName }}</span>
      <div
        :class="{ 'tw-w-full tw-mt-2 tw-mb-4': !itemIsBooleanChoice }"
        class="tw-flex tw-flex-row tw-justify-between tw-grow tw-flex-auto"
      >
        <v-radio-group v-if="itemIsBooleanChoice" v-model="attribut.status" class="radios" :column="false">
          <v-radio label="le magasin propose" :value="1" />
          <v-radio class="tw-ml-4" label="le magasin ne propose pas" :value="0" />
        </v-radio-group>
        <v-select
          v-else
          v-model="attributValues"
          class="radios locationGmb-googleUpdates-itemAttribut-child__select"
          :label="$t('locationsGmb.id.googleUpdates.select')"
          item-text="displayName"
          item-value="value"
          deletable-chips
          outlined
          multiple
          small-chips
          dense
          hide-details
          :items="attribut.valueMetadata"
        />
        <ui-button
          class="locationGmb-googleUpdates-itemAttribut-child__button"
          :icon="icons.mdiCheck"
          vuetifyIcon
          small
          button="primary"
          variant="data"
          :label="$t('locationsGmb.id.googleUpdates.send')"
          @click="
            $emit('updateStatus', {
              status: itemIsBooleanChoice ? attribut.status : attributValues.join(','),
              id: attribut.id,
            })
          "
        />
      </div>
    </div>
  </v-card-text>
</template>

<script>
import { mdiCheck } from '@mdi/js'
import UiButton from '@/components/UI/Button.vue'

export default {
  props: {
    attribut: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    UiButton,
  },
  data() {
    return {
      icons: {
        mdiCheck,
      },
      attributValues: [],
    }
  },
  computed: {
    itemIsBooleanChoice() {
      return this.attribut.valueMetadata.length <= 2
    },
  },
}
</script>

<style lang="scss" scoped>
.locationGmb-googleUpdates-itemAttribut-child {
  @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-flex-wrap;
  &__label {
    @apply tw-mr-4;

    flex: 1 0 150px;
  }

  &__select {
    max-width: 500px;
  }

  &__button {
    @apply tw-ml-4 tw-my-auto;

    color: $blue-dodger !important;
  }
}
</style>
