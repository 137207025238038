<template>
  <div class="locations-gmb-id" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      v-if="!hasError"
      class="locations-gmb-id__header"
      :title="formattedLocationName"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      :right-content="true"
      back-button
    >
      <div class="locations-gmb-id__header__link-to-location__button" v-if="isLoading">
        <skeleton-input margin-left="24px" />
      </div>
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".locations-gmb-id__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else-if="hasUrls"
      >
        <ui-button
          class="locations-gmb-id__header__cta"
          button="cta"
          variant="data"
          icon="external_link"
          :label="$t('locationsGmb.id.header.goToLocation.label')"
          :mobile-toggle="true"
        />
        <template slot="popover">
          <div class="locations-gmb-id__header__popover">
            <a
              :href="currentLocation.websiteUri"
              class="locations-gmb-id__header__popover__link"
              v-if="currentLocation.websiteUri"
              target="_blank"
            >
              {{ $t('locationsGmb.id.header.link.website') }}
            </a>
            <a
              :href="currentLocation.metadata.mapsUri"
              class="locations-gmb-id__header__popover__link"
              v-if="currentLocation.metadata.mapsUri"
              target="_blank"
            >
              {{ $t('locationsGmb.id.header.link.maps') }}
            </a>
            <a
              :href="currentLocation.metadata.serpUrl"
              class="locations-gmb-id__header__popover__link"
              v-if="currentLocation.metadata.serpUrl"
              target="_blank"
            >
              {{ $t('locationsGmb.id.header.link.serp') }}
            </a>
            <a
              :href="currentLocation.metadata.newReviewUri"
              class="locations-gmb-id__header__popover__link"
              v-if="currentLocation.metadata.newReviewUri"
              target="_blank"
            >
              {{ $t('locationsGmb.id.header.link.newReview') }}
            </a>
            <a :href="qaUrl" class="locations-gmb-id__header__popover__link" v-if="qaUrl.length > 0" target="_blank">
              {{ $t('locationsGmb.id.header.link.qa') }}
            </a>
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-error
      v-if="hasError"
      :route="{ name: 'LocationsGmb', params: { account: { name: this.selectedGmbAccount } } }"
      :label="$t('locationsGmb.id.error.button.back')"
    />
    <screen-container direction="column" v-else>
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />

      <!-- General Section -->
      <location-gmb-general
        v-if="currentTab === 'general'"
        :is-loading="isLoading"
        :is-updating="isUpdating"
        :current-location="currentLocation"
        :current-client="currentClient"
        :countries="countries"
        :categories="categories"
        :public-holidays="publicHolidays"
        :locale-lang="$i18n.locale"
        @editSimpleField="editSimpleField"
        @editAddress="editAddress"
        @editCoordinates="editCoordinates"
        @editOpeningHours="editOpeningHours"
        @editExceptionalHours="editExceptionalHours"
        @updateOpenStatus="updateOpenStatus"
        @getPublicHolidays="getPublicHolidays"
      />
      <location-gmb-extra
        v-if="currentTab === 'extras'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-client="currentClient"
        :locations-search="locations"
        :current-location-mapping="currentLocationMapping"
        @editSimpleField="editSimpleField"
        @editLocationMapping="editLocationMapping"
      />
      <location-gmb-attribut
        v-if="currentTab === 'attributs'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-client="currentClient"
        :all-categorys="allCategorys"
        @editAttribute="editAttribute"
        @editCategory="editCategory"
        @editGmbAutocomplete="editGmbAutocomplete"
      />
      <location-gmb-photo
        v-if="currentTab === 'photos'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-location-medias="currentLocationMedias"
        :current-client="currentClient"
        @deletePhoto="showDeletePhotoModal"
        @addPhoto="showAddPhotoModal"
        @showPreview="showPreview"
        @flagClick="flagClick"
      />
      <location-gmb-google-updates
        v-if="currentTab === 'googleUpdates'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :gu-administratives="GUAdministratives"
        :gu-attributs="GUAttributs"
        @updateStatus="updateGUStatus"
        @updateStatusAttribut="updateGUStatusAttribut"
      />
    </screen-container>
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @saveMapping="saveMapping"
      @closed="closedModal"
      @search="searchLocations"
    />
    <modal-address
      v-if="isEditing && editingModal === 'address'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveAddress"
      @closed="closedModal"
    />
    <modal-coordinates
      v-if="isEditing && editingModal === 'coordinates'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-hours
      v-if="isEditing && editingModal === 'openingHours'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      :hours-format="true"
      :current-client="currentClient"
      @save="saveOpeningHours"
      @closed="closedModal"
    />
    <modal-exceptional-hours
      v-if="isEditing && editingModal === 'exceptionalHours'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      :hours-format="true"
      :current-client="currentClient"
      @save="saveExceptionalHours"
      @closed="closedModal"
    />
    <modal-gmb-autocomplete
      v-if="isEditing && editingModal === 'gmbAutocomplete'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveGmbAutocomplete"
      @closed="closedModal"
    />
    <modal-gmb-photo
      v-if="editingModal === 'gmbPhoto'"
      :photo="modal.data"
      :type="modal.parameters.type"
      @closed="closedModal"
      @deletePhoto="deletePhoto"
      @save="deletePhoto"
    />
    <modal-media
      v-if="isEditing && editingModal === 'addPhoto'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      @add="addPhoto"
      @closed="closedModal"
    />
    <modal-gmb
      v-if="isEditing && editingModal === 'attributs'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      @save="saveAttributes"
      @closed="closedModal"
    />
    <modal-gmb-category
      v-if="isEditing && editingModal === 'category'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      :current-location="currentLocation"
      @save="saveCategory"
      @closed="closedModal"
      @updateOptions="updateOptions"
    />
    <modal-confirm
      v-if="isEditing && editingModal === 'deletePhoto'"
      :parameters="modal.parameters"
      @save="deletePhoto(modal.parameters.photo)"
      @cancel="closedModal"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import { recordFlagEvent } from '@/services/gmb.service'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import ScreenError from '@/components/Screen/Error.vue'
import LocationGmbGeneral from '@/components/LocationGmb/General.vue'
import LocationGmbExtra from '@/components/LocationGmb/Extra.vue'
import LocationGmbAttribut from '@/components/LocationGmb/Attribut.vue'
import LocationGmbPhoto from '@/components/LocationGmb/Photo.vue'
import LocationGmbGoogleUpdates from '@/components/LocationGmb/GoogleUpdates.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import UiButton from '@/components/UI/Button.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalAddress from '@/components/Modal/Address.vue'
import ModalCoordinates from '@/components/Modal/Coordinates.vue'
import ModalHours from '@/components/Modal/Hours.vue'
import ModalExceptionalHours from '@/components/Modal/ExceptionalHours.vue'
import ModalGmbPhoto from '@/components/Modal/GmbPhoto.vue'
import ModalGmbAutocomplete from '@/components/Modal/GmbAutocomplete.vue'
import ModalMedia from '@/components/Modal/Media.vue'
import ModalGmb from '@/components/Modal/Gmb.vue'
import ModalGmbCategory from '@/components/Modal/GmbCategory.vue'
import ModalConfirm from '@/components/Modal/Confirm.vue'
import { timeFromNow } from '@/utils/hours.util'
import get from 'lodash/get'
import set from 'lodash/set'
import differenceBy from 'lodash/differenceBy'
import dayjs from 'dayjs'
import { importPicture } from '@/services/gmb.service'
import { userRoles } from '@/config/permissions.config'
import { isUserAuthorized } from '@/config/permissions.config'
import { URLS } from '@/config/urls.config'

const initialModal = () => ({
  objKey: null,
  data: null,
  label: null,
  parameters: {
    type: 'text',
    mode: 'input',
    required: false,
    number: null,
    options: [],
  },
})
export default {
  name: 'LocationsIdGmb',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    ScreenError,
    LocationGmbGeneral,
    LocationGmbExtra,
    LocationGmbAttribut,
    LocationGmbPhoto,
    LocationGmbGoogleUpdates,
    SkeletonInput,
    UiButton,
    ModalSimpleField,
    ModalAddress,
    ModalCoordinates,
    ModalHours,
    ModalExceptionalHours,
    ModalGmbAutocomplete,
    ModalGmbPhoto,
    ModalGmb,
    ModalMedia,
    ModalConfirm,
    ModalGmbCategory,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isEditing: false,
      editingModal: '',
      modal: initialModal(),
      hasError: false,
      tabsElement: null,
      categories: [],
      userRoles,
      locationsSearch: {},
    }
  },
  async created() {
    this.isLoading = true
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('general')
    }
    try {
      await Promise.all([
        await this.getLocations({ tableParams: null, search: this.search }),
        await this.getCurrentLocationsMapping({
          id: this.currentClientId,
          accountGmb: this.extractLocationFromName,
          account: this.selectedGmbAccount,
        }),
        await this.loadLocation({
          locationName: this.extractLocationFromName,
          currentAccountName: this.selectedGmbAccount,
          period: 'current',
        }),
        await this.getGUAdministratives({
          id: this.extractLocationFromName,
          clientId: this.currentClientId,
        }),
        await this.getGUAttributs({
          id: this.extractLocationFromName,
          clientId: this.currentClientId,
        }),
        this.loadLocationMedias({
          locationName: this.selectedGmbLocation,
          currentAccountName: this.selectedGmbAccount,
        }),
        this.getPublicHolidays({
          locale: this.currentLocation?.storefrontAddress?.regionCode,
          from: dayjs().format('YYYY-MM-DD'),
          length: 45,
        }),
      ])
      this.isLoading = false
    } catch (err) {
      this.$log.error(err)
      this.hasError = true
    }
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
      currentLocation: state => state.gmb.currentLocation,
      currentLocationMapping: state => state.location.currentLocationMapping,
      currentLocationMedias: state => state.gmb.currentLocationMedias,
      publicHolidays: state => state.gmb.publicHolidays,
      GUAdministratives: state => state.gmb.GUAdministratives,
      GUAttributs: state => state.gmb.GUAttributs,
      currentClient: state => state.client.currentClient,
      currentClientId: state => state.client.currentClient?.id,
      countries: state => state.backoffice.countries,
    }),
    extractLocationFromName() {
      return this.selectedGmbLocation.substring(
        this.selectedGmbLocation.indexOf('locations/'),
        this.selectedGmbLocation.length
      )
    },
    qaUrl() {
      if (this.currentLocation?.metadata?.serpUrl?.length > 0) {
        const id = new URL(this.currentLocation.metadata.serpUrl).searchParams.get('id')
        return `${URLS.LOCAL_GOOGLE}/place?id=${id}&use=srp#lpqa=d,2`
      }
      return ''
    },
    selectedGmbAccount() {
      const routeParams = this.$route.params.name.split('/')
      return `${routeParams[0]}/${routeParams[1]}`
    },
    selectedGmbLocation() {
      return this.$route.params.name
    },
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('locationsGmb.list.breadcrumb'),
          route: {
            name: 'LocationsGmb',
            params: {
              name: this.selectedGmbAccount,
            },
          },
        },
        {
          label: this.formattedLocationName,
          route: {
            name: 'LocationGmb',
            params: {
              name: this.currentLocation.name,
            },
          },
        },
      ]
    },
    tabs() {
      if (this.currentLocation.categories?.primaryCategory) {
        return [
          {
            label: this.$t('locationsGmb.id.tab.general'),
            key: 'general',
          },
          {
            label: this.$t('locationsGmb.id.tab.attribut'),
            key: 'attributs',
          },
          {
            label: this.$t('locationsGmb.id.tab.photos'),
            key: 'photos',
          },
          isUserAuthorized(userRoles.superAdmin)
            ? {
                label: this.$t('locationsGmb.id.tab.extras'),
                key: 'extras',
              }
            : {},
          {
            label: this.$t('locationsGmb.id.tab.googleUpdates'),
            key: 'googleUpdates',
            badge: {
              display: true,
              content: this.currentLocation.pendingUpdates?.count_gmb_upd_total,
            },
          },
        ]
      }
      return [
        {
          label: this.$t('locationsGmb.id.tab.home'),
          key: 'home',
        },
        {
          label: this.$t('locationsGmb.id.tab.general'),
          key: 'general',
        },
        {
          label: this.$t('locationsGmb.id.tab.photos'),
          key: 'photos',
        },
        isUserAuthorized(userRoles.superAdmin)
          ? {
              label: this.$t('locationsGmb.id.tab.extras'),
              key: 'extras',
            }
          : {},
        {
          label: this.$t('locationsGmb.id.tab.googleUpdates'),
          key: 'googleUpdates',
          badge: {
            display: true,
            content: this.currentLocation.pendingUpdates?.count_gmb_upd_total,
          },
        },
      ]
    },
    modalTitle() {
      return this.$t('common.modal.title.location', {
        location: this.currentLocation.title,
      })
    },
    hasUrls() {
      if (
        (typeof this.currentLocation.websiteUri === 'string' && this.currentLocation.websiteUri !== '') ||
        (typeof this.currentLocation.metadata?.mapsUri === 'string' && this.currentLocation.metadata.mapsUri !== '') ||
        (typeof this.currentLocation.metadata?.newReviewUri === 'string' &&
          this.currentLocation.metadata.newReviewUri !== '')
      ) {
        return true
      }
      return false
    },
    formattedLocationName() {
      return this.currentLocation?.storeCode
        ? `${this.currentLocation.title} (${this.currentLocation.storeCode})`
        : this.currentLocation.title
    },
    allCategorys() {
      let primaryCategory = []
      if (this.currentLocation.categories?.primaryCategory?.name) {
        primaryCategory.categoryId = primaryCategory.name
        primaryCategory = [this.currentLocation.categories?.primaryCategory]
      }
      const additionalCategories = this.currentLocation.categories?.additionalCategories?.map(categorie => {
        return { ...categorie, categoryId: categorie.name }
      })
      return _.unionBy(
        primaryCategory,
        this.currentLocation.serviceItems || [],
        additionalCategories || [],
        'categoryId'
      )
    },
  },
  methods: {
    ...mapActions({
      getCurrentLocationsMapping: 'location/getCurrentLocationsMapping',
      updateCurrentLocationsMapping: 'location/updateCurrentLocationsMapping',
      loadLocation: 'gmb/loadLocation',
      loadLocationMedias: 'gmb/loadLocationMedias',
      getPublicHolidays: 'gmb/getPublicHolidays',
      updateLocation: 'gmb/updateLocation',
      getGUAdministratives: 'gmb/getGUAdministratives',
      getGUAttributs: 'gmb/getGUAttributs',
      postGUAdministrative: 'gmb/postGUAdministrative',
      postGUAttribut: 'gmb/postGUAttribut',
      deleteLocationMedia: 'gmb/deleteLocationMedia',
      getLocations: 'location/getLocations',
    }),
    async updateGUStatus({ status, id, index }) {
      try {
        await this.postGUAdministrative({ clientId: this.currentClientId, status, id, index })
        document.activeElement.blur()
        this.notificationSuccess('location', this.currentLocation.title)
      } catch (err) {
        this.notificationError()
      }
    },
    async updateGUStatusAttribut({ status, id, key }) {
      try {
        await this.postGUAttribut({ clientId: this.currentClientId, status, id, key })
        document.activeElement.blur()
        this.notificationSuccess('location', this.currentLocation.title)
      } catch {
        this.notificationError()
      }
    },
    searchLocations(e) {
      if (this.modal.objKey === 'locationsMapping') {
        this.search = e
        this.getLocations({ tableParams: null, search: this.search }).then(() => {
          this.modal.parameters = {
            ...this.modal.parameters,
            options: this.locations,
          }
        })
      }
    },
    updateOptions(options) {
      this.modal.parameters.options = options
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
    formatedDate(date) {
      return timeFromNow(date)
    },
    editSimpleField(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`locationsGmb.id.modal.${payload.objKey}`)
      this.modal.data = get(this.currentLocation, payload.objKey)
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editLocationMapping(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`locationsGmb.id.modal.${payload.objKey}`)
      this.modal.data = this.currentLocationMapping
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editAddress(payload) {
      this.modal.data = payload.storefrontAddress
      this.isEditing = true
      this.editingModal = 'address'
      this.$nextTick(() => {
        this.$modal.show('modal-address')
      })
    },
    editCoordinates(payload) {
      this.modal.data = payload.coordinates
      this.isEditing = true
      this.editingModal = 'coordinates'
      this.$nextTick(() => {
        this.$modal.show('modal-coordinates')
      })
    },
    editOpeningHours(payload) {
      this.modal.data = payload.hours
      this.isEditing = true
      this.editingModal = 'openingHours'
      this.modal.parameters.type = 'gmb'
      this.$nextTick(() => {
        this.$modal.show('modal-hours')
      })
    },
    editExceptionalHours(payload) {
      this.modal.data = payload.exceptionalHour
      this.isEditing = true
      this.editingModal = 'exceptionalHours'
      this.modal.parameters.type = 'gmb'
      this.$nextTick(() => {
        this.$modal.show('modal-exceptional-hours')
      })
    },
    editAttribute(payload) {
      this.modal.data = payload.attribut
      this.modal.parameters.options = payload.refs
      this.isEditing = true
      this.editingModal = 'attributs'
      this.$nextTick(() => {
        this.$modal.show('modal-gmb')
      })
    },
    editCategory(payload) {
      const data = payload.services
      data?.forEach(service => {
        service.id = `${Math.random().toString(36).slice(2)}}`
      })
      this.modal.data = data || []
      this.modal.parameters.options = data || []
      this.modal.parameters.label = payload.displayName
      this.modal.parameters.categoryId = payload.categoryId || payload.name
      this.isEditing = true
      this.editingModal = 'category'
      this.$nextTick(() => {
        this.$modal.show('modal-gmb-category')
      })
    },
    editGmbAutocomplete(serviceArea) {
      this.modal.data = serviceArea
      this.isEditing = true
      this.editingModal = 'gmbAutocomplete'
      this.$nextTick(() => {
        this.$modal.show('modal-gmb-autocomplete')
      })
    },
    saveSimpleField(params) {
      this.isUpdating = true
      this.updateLocation({
        data: set({}, Object.keys(params.objKey)[0], Object.values(params.objKey)[0]),
        currentAccountName: this.selectedGmbAccount,
      })
        .then(() => {
          this.$modal.hide('modal-simple-field')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveMapping(params) {
      this.isUpdating = true
      const ids = params.objKey.locationsMapping.map(location => location.id)
      this.updateCurrentLocationsMapping({
        id: this.currentClientId,
        accountGmb: this.extractLocationFromName,
        account: this.selectedGmbAccount,
        placeId: this.currentLocation.metadata.placeId,
        locationsId: ids,
      })
        .then(() => {
          this.$modal.hide('modal-simple-field')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveOpeningHours(params) {
      this.isUpdating = true
      this.updateLocation({ data: { regularHours: params.objKey }, currentAccountName: this.selectedGmbAccount })
        .then(() => {
          this.$modal.hide('modal-hours')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveExceptionalHours(params) {
      this.isUpdating = true
      let eoh = [...this.currentLocation.specialHours.specialHourPeriods] || []
      if (params.status === 'create') {
        eoh.push(params.objKey)
      } else if (params.status === 'delete') {
        eoh = eoh.filter(hour => hour.id !== params.objKey.id)
      } else if (params.status === 'update') {
        eoh = eoh.map(hour => (hour.id === params.objKey.id ? params.objKey : hour))
      }
      this.updateLocation({
        data: { specialHours: { specialHourPeriods: eoh } },
        currentAccountName: this.selectedGmbAccount,
      })
        .then(() => {
          this.$modal.hide('modal-exceptional-hours')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveAddress(params) {
      this.isUpdating = true
      const addressObj = {
        addressLines: [],
        locality: params.objKey.city,
        postalCode: params.objKey.postalCode,
        regionCode: params.objKey.countryCode,
      }
      if (params.objKey.street1 !== '') {
        addressObj.addressLines.push(params.objKey.street1)
      }
      if (params.objKey.street2 !== '') {
        addressObj.addressLines.push(params.objKey.street2)
      }
      this.updateLocation({ data: { storefrontAddress: addressObj }, currentAccountName: this.selectedGmbAccount })
        .then(() => {
          this.$modal.hide('modal-address')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveAttributes(params) {
      this.isUpdating = true
      const attrs = differenceBy(this.currentLocation.attributes, params.objKey, 'group')
      const filtered = attrs.filter(attr => params.attributesToRemove.findIndex(value => value === attr.name) === -1)
      this.updateLocation({
        data: { attributes: [...filtered, ...params.objKey] },
        currentAccountName: this.selectedGmbAccount,
      })
        .then(() => {
          this.$modal.hide('modal-gmb')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveCategory({ currentServices, categoryId, displayName }) {
      const index = this.allCategorys.findIndex(
        category => category.categoryId === categoryId || category.name === categoryId
      )
      this.allCategorys.splice(index, 1, {
        displayName,
        categoryId,
        services: [...currentServices],
      })
      this.isUpdating = true
      const data = {
        serviceItems: [...this.allCategorys],
      }
      this.updateLocation({
        data,
        currentAccountName: this.selectedGmbAccount,
      })
        .then(() => {
          this.$modal.hide('modal-gmb-category')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    saveGmbAutocomplete(params) {
      this.isUpdating = true
      this.updateLocation({ data: params, currentAccountName: this.selectedGmbAccount })
        .then(() => {
          this.$modal.hide('modal-gmb-autocomplete')
          this.closedModal()
          this.notificationSuccess('location', this.currentLocation.title)
        })
        .catch(() => this.notificationError())
    },
    showPreview(params) {
      this.modal.data = params.photo
      this.modal.parameters.type = params.type
      this.editingModal = 'gmbPhoto'
      this.$nextTick(() => {
        this.$modal.show('modal-gmb-photo')
      })
    },
    flagClick(url) {
      recordFlagEvent(this.currentClientId, url, this.selectedGmbAccount)
    },
    showDeletePhotoModal(photo) {
      this.isEditing = true
      this.modal.parameters.photo = photo
      this.modal.parameters.mode = 'confirmOwnerwPictureDeletion'
      this.editingModal = 'deletePhoto'
      this.$nextTick(() => {
        this.$modal.show('modal-confirm')
      })
    },
    async deletePhoto({ name: locationMediaId }) {
      try {
        await this.deleteLocationMedia({
          accountName: this.selectedGmbAccount,
          locationMediaId,
        })
        await this.loadLocationMedias({
          locationName: this.selectedGmbLocation,
          currentAccountName: this.selectedGmbAccount,
        })
        this.notificationSuccess('location', this.currentLocation.title)
      } catch (err) {
        this.notificationError()
      }
      this.closedModal()
    },
    updateOpenStatus(status) {
      this.isUpdating = true
      this.updateLocation({ data: { openInfo: { status } }, currentAccountName: this.selectedGmbAccount })
        .then(() => {
          this.notificationSuccess('location', this.currentLocation.title)
          this.closedModal()
        })
        .catch(() => this.notificationError())
    },
    showAddPhotoModal({ category }) {
      this.isEditing = true
      this.editingModal = 'addPhoto'
      this.modal.parameters.category = category
      this.modal.parameters.type = 'locationPictures'
      this.$nextTick(() => {
        this.$modal.show('modal-media')
      })
    },
    async addPhoto(params) {
      this.isUpdating = true
      try {
        await importPicture({
          clientId: this.currentClientId,
          account: this.selectedGmbAccount,
          locationName: this.selectedGmbLocation,
          mediaCategory: this.modal.parameters.category,
          file: params.image,
          name: this.currentLocation.title,
        })
        await this.loadLocationMedias({
          locationName: this.selectedGmbLocation,
          currentAccountName: this.selectedGmbAccount,
        })
        this.notificationSuccess('location', this.currentLocation.title)
      } catch (err) {
        this.notificationError()
      }
      this.isEditing = false
      this.$modal.hide('modal-media')
      this.closedModal()
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
      this.modal = initialModal()
    },
  },
}
</script>

<style lang="scss">
.locations-gmb-id {
  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__link-to-location {
      &__button {
        position: fixed;
        right: $gutter-mobile;
        bottom: $gutter-mobile;
        z-index: 2;

        @media (min-width: $screen-sm) {
          position: relative;
          right: initial;
          bottom: initial;
          margin-left: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          margin-left: $gutter-desktop;
        }
      }
    }

    &__popover {
      min-width: 280px;

      @media (min-width: $screen-sm) {
        min-width: 340px;
      }

      &__link {
        display: flex;
        align-items: center;
        padding: 0 $gutter-mobile;
        height: 48px;
        color: var(--text-color);

        @media (min-width: $screen-sm) {
          padding: 0 $gutter-tablet;
        }

        &:hover {
          background-color: var(--bg-color-hover);
          cursor: pointer;
        }

        &:first-child {
          border-top-left-radius: $radius-default;
          border-top-right-radius: $radius-default;
        }

        &:last-child {
          border-bottom-left-radius: $radius-default;
          border-bottom-right-radius: $radius-default;
        }

        &__icon {
          margin-right: $gutter-mobile;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>
