<template>
  <modal
    :title="title"
    name="modal-gmb-autocomplete"
    :has-apply="true"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <gmap-autocomplete
        class="introInput"
        :options="autocompleteOptions"
        :fields="autocompleteFields"
        @place_changed="onPlaceChanged"
      >
        <template v-slot:input="slotProps">
          <div class="gmb-autocomplete__input">
            <div class="gmb-autocomplete__input__wrapper">
              <input
                class="gmb-autocomplete__input__wrapper__field"
                :class="{
                  'gmb-autocomplete__input__wrapper__field--error': error,
                }"
                placeholder=" "
                ref="input"
                v-on:listeners="slotProps.listeners"
                v-on:attrs="slotProps.attrs"
              />
              <label
                class="gmb-autocomplete__input__wrapper__label"
                :class="{
                  'gmb-autocomplete__input__wrapper__label--error': error,
                }"
              >
                {{ $t('modal.gmbAutocomplete.label.placeholder') }}
              </label>
            </div>
            <transition name="fade">
              <div class="gmb-autocomplete__input__helper gmb-autocomplete__input__helper--error" v-if="error">
                <slot>{{ $t('modal.gmbAutocomplete.helper.error') }}</slot>
              </div>
            </transition>
          </div>
        </template>
      </gmap-autocomplete>
      <ul class="gmb-autocomplete__places-list">
        <li v-for="place in placeInfos" :key="place.placeName" class="gmb-autocomplete__places-list__place">
          {{ place.placeName }}
          <span
            class="gmb-autocomplete__places-list__place__delete ui-button__icon ui-button__icon--default backoffice-icons"
            @click="onPlaceRemoved(place)"
          >
            close
          </span>
        </li>
      </ul>
    </template>
  </modal>
</template>

<script>
import merge from 'lodash/merge'

export default {
  name: 'ModalGmbAutocomplete',
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: {},
      autocomplete: null,
      autocompleteOptions: {
        types: ['(regions)'],
      },
      autocompleteFields: ['place_id', 'name'],
      error: null,
    }
  },
  mounted() {
    const data = merge(this.data, {
      serviceArea: {
        businessType: 'CUSTOMER_AND_BUSINESS_LOCATION',
        places: {
          placeInfos: [],
        },
      },
    })
    this.modelData = JSON.parse(JSON.stringify(data))
  },
  computed: {
    placeInfos() {
      return this.modelData.serviceArea.places.placeInfos
    },
  },
  methods: {
    onPlaceChanged(place) {
      if (place.place_id) {
        let placeReorganized = {}
        placeReorganized.placeId = place.place_id
        placeReorganized.placeName = place.name

        if (!JSON.stringify(this.modelData.serviceArea.places.placeInfos).includes(JSON.stringify(placeReorganized))) {
          this.modelData.serviceArea.places.placeInfos.push(placeReorganized)
          this.error = false
        }
      } else {
        this.error = true
      }

      this.$refs.input.value = ''
      this.$refs.input.blur()
    },
    onPlaceRemoved(place) {
      let index = this.modelData.serviceArea.places.placeInfos.indexOf(place)
      if (index > -1) {
        this.modelData.serviceArea.places.placeInfos.splice(index, 1)
        this.error = false
      }
    },
    save() {
      if (this.modelData.serviceArea?.places?.placeInfos?.length === 0) this.modelData = { serviceArea: {} }
      this.$emit('save', this.modelData)
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss">
.gmb-autocomplete {
  &__input {
    @include input;

    padding-top: 20px;

    &__wrapper {
      @include input-wrapper;

      &__field {
        @include input-field;
      }

      &__label {
        @include input-label;
      }
    }

    &__helper {
      @include input-helper;
    }
  }

  &__places-list {
    margin: 0;
    padding: 0;

    &__place {
      display: flex;
      gap: 15px;
      align-items: center;
      margin: 15px 0;
      cursor: default;

      &__delete {
        cursor: pointer;
      }
    }
  }
}

.pac-container {
  position: absolute;
  border-top: 1px solid $navigation-color-border;
  border-radius: $radius-default;
  box-shadow: $navigation-box-shadow;
  background-color: $navigation-color-bg;
  overflow: hidden;

  &:after {
    background-image: none !important;
    height: 0;
  }

  .pac-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: background-color $transition-duration-fast $transition-effect-default,
      color $transition-duration-fast $transition-effect-default;
    background-color: $navigation-color-bg;
    padding: 0 $spacing-lg / 2;
    height: 48px;
    text-decoration: none;
    font-size: $font-size-default;

    &:hover {
      background-color: $navigation-color-bg-hover;
      cursor: pointer;
      color: var(--product-color);
    }

    .pac-item-query {
      margin-right: 8px;
      color: var(--text-color);
      font-size: $font-size-default;
    }
    .pac-icon {
      display: none;
    }
  }
}
</style>
