<template>
  <div class="locationGmb-google-updates">
    <div class="locationGmb-google-updates__block">
      <skeleton-line v-if="isLoading" height="20px" width="200px" />
      <div v-else class="tw-mr-2 tw-text-base">
        {{ $t('locationsGmb.id.googleUpdates.title', { count: pendingUpdatesByCategory }) }}
      </div>
      <skeleton-line v-if="isLoading" height="30px" width="120px" margin-left="16px" />
      <ui-button
        v-else
        medium
        class="locationGmb-google-updates__button"
        :class="{ 'locationGmb-google-updates__button--active': currentTab === 'guAdmin' }"
        button="primary"
        variant="data"
        :label="
          $t('locationsGmb.id.googleUpdates.button.admin', { count: currentLocation.pendingUpdates.count_gmb_upd })
        "
        @click="currentTab = 'guAdmin'"
      />
      <skeleton-line v-if="isLoading" height="30px" width="120px" margin-left="16px" />
      <ui-button
        v-else
        medium
        class="locationGmb-google-updates__button"
        :class="{ 'locationGmb-google-updates__button--active': currentTab === 'guAttributs' }"
        button="primary"
        variant="data"
        :label="
          $t('locationsGmb.id.googleUpdates.button.attributs', {
            count: currentLocation.pendingUpdates.count_gmb_upd_attribute,
          })
        "
        @click="currentTab = 'guAttributs'"
      />
    </div>
    <div class="tw-flex tw-justify-center tw-mt-24" v-if="isLoading">
      <v-progress-circular width="3" size="70" indeterminate color="#77a3fd" />
    </div>

    <div v-else>
      <v-fade-transition
        v-if="
          (guAdministratives.length > 0 && currentTab === 'guAdmin') ||
          (Object.keys(guAttributs).length > 0 && currentTab === 'guAttributs')
        "
        class="locationGmb-google-updates__fields"
        :class="{ 'locationGmb-google-updates__fields--dark': this.$vuetify.theme.dark }"
        group
      >
        <template v-if="currentTab === 'guAdmin'">
          <location-gmb-google-updates-item-admin
            v-for="(guUpdate, index) in guAdministratives"
            :key="`GU admin ${guUpdate.id}`"
            :item="guUpdate"
            @updateStatus="payload => $emit('updateStatus', { ...payload, index })"
          />
        </template>
        <template v-else>
          <location-gmb-google-updates-item-attribut
            v-for="(itemsCategory, key) in guAttributs"
            :key="`GU admin ${key}`"
            :label="key"
            :items="itemsCategory"
            @updateStatus="payload => $emit('updateStatusAttribut', { ...payload, key })"
          />
        </template>
      </v-fade-transition>
      <div v-else>
        <v-card
          :class="{ 'locationGmb-google-updates__fields--dark': this.$vuetify.theme.dark }"
          class="locationGmb-google-updates__fields locationGmb-google-updates__fields--no-data"
          elevation="0"
        >
          <v-container class="tw-flex tw-items-center tw-justify-center" fluid>
            <v-icon class="tw-mr-8" size="65" color="success">{{ icons.mdiCheckOutline }}</v-icon>
            {{ $t('locationsGmb.id.googleUpdates.noData') }}
          </v-container>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiCheckOutline } from '@mdi/js'
import UiButton from '@/components/UI/Button.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import LocationGmbGoogleUpdatesItemAdmin from '@/components/LocationGmb/GoogleUpdates/ItemAdmin'
import LocationGmbGoogleUpdatesItemAttribut from '@/components/LocationGmb/GoogleUpdates/ItemAttribut'

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    guAdministratives: {
      type: Array,
      required: true,
    },
    guAttributs: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiCheckOutline,
      },
      currentTab: 'guAdmin',
    }
  },
  components: {
    SkeletonLine,
    UiButton,
    LocationGmbGoogleUpdatesItemAdmin,
    LocationGmbGoogleUpdatesItemAttribut,
  },
  computed: {
    pendingUpdatesByCategory() {
      return this.currentTab === 'guAdmin'
        ? this.currentLocation.pendingUpdates.count_gmb_upd
        : this.currentLocation.pendingUpdates.count_gmb_upd_attribute
    },
  },
}
</script>

<style lang="scss" scoped>
.locationGmb-google-updates {
  @apply tw-mt-4 md:tw-mt-0;

  &__button {
    @apply tw-text-xs;

    color: $blue-dodger !important;

    &--active {
      background-color: $blue-dodger !important;
      color: $white !important;
    }

    &--accept {
      margin-left: auto;
      width: 45%;
      color: $green-mantis !important;
    }
    &--reject {
      width: 45%;
      color: $red-pomegranate !important;
    }
  }

  &__block {
    @apply tw-flex tw-flex-row tw-items-center tw-gap-4;
  }

  &__fields {
    @apply tw-mt-8 tw-py-4 md:tw-py-10 tw-px-2 tw-mx-0 md:tw-mx-12 tw-rounded-lg tw-flex tw-flex-col tw-items-center;

    background-color: $white;

    &--dark {
      background-color: $interface-dark-grade-1-color;
    }

    &--no-data {
      @apply tw-font-medium;

      width: 90%;
      font-size: $font-size-xl;
    }
  }
}
</style>
