<template>
  <modal name="modal-gmb-photo" :has-apply="false" :cancel-label="$t('common.button.close')">
    <template v-slot:container>
      <div class="modal-gmb-photo__preview">
        <div class="modal-gmb-photo__top">
          <ui-button
            class="modal-gmb-photo__top__link"
            variant="white"
            button="secondary"
            :label="$t('modal.gmbPhoto.button.link')"
            icon="external_link"
            :icon-only="true"
            :is-link="true"
            :href="photo.googleUrl"
          />
          <v-popover placement="bottom-end" :autoHide="true" popoverInnerClass="popover-inner no-overflow">
            <ui-button
              class="modal-gmb-photo__top__delete"
              variant="error"
              button="secondary"
              :label="$t('modal.gmbPhoto.button.delete.label')"
              icon="delete"
              :icon-only="true"
              v-if="type === 'owner'"
            />
            <template slot="popover">
              <div class="modal-gmb-photo__popover__container">
                <div class="modal-gmb-photo__popover__container__text">
                  {{ $t('modal.gmbPhoto.button.delete.popover.label') }}
                </div>
                <div class="modal-gmb-photo__popover__container__buttons">
                  <ui-button
                    class="modal-gmb-photo__popover__container__buttons__delete"
                    button="secondary"
                    :label="$t('modal.gmbPhoto.button.delete.popover.yes')"
                    @click="deletePhoto"
                  />
                  <ui-button :label="$t('modal.gmbPhoto.button.delete.popover.no')" v-close-popover button="primary" />
                </div>
              </div>
            </template>
          </v-popover>
          <ui-button
            variant="error"
            class="modal-gmb-photo__top__report"
            button="secondary"
            :label="$t('modal.gmbPhoto.button.report')"
            icon="flag"
            :icon-only="true"
            v-if="type === 'customer'"
            :is-link="true"
            :href="photo.attribution.takedownUrl"
          />
        </div>
        <img :src="photo.googleUrl" class="modal-gmb-photo__preview__img" />
      </div>
    </template>
    <template v-slot:actions>
      <div class="modal-gmb-photo__footer">
        <div class="modal-gmb-photo__footer__content">
          <div class="modal-gmb-photo__footer__content__first-row">
            <div>
              {{
                $t('modal.gmbPhoto.footer.created', {
                  date: formatedDate(photo.createTime, 'L', $i18n.locale),
                  dateFromNow: formatedTime(photo.createTime),
                })
              }}
            </div>
            <a
              v-if="photo.attribution && photo.attribution.profileName"
              :href="photo.attribution.profileUrl"
              target="_blank"
            >
              {{ $t('modal.gmbPhoto.footer.author', { name: photo.attribution.profileName }) }}
            </a>
          </div>
          <div class="modal-gmb-photo__footer__content__second-row">
            <div class="modal-gmb-photo__footer__content__second-row__block">
              <v-icon class="modal-gmb-photo__footer__content__second-row__block__icon">{{ icons.mdiEye }}</v-icon>
              {{
                $t('modal.gmbPhoto.footer.view', {
                  count: photo.insights.viewCount,
                })
              }}
            </div>
            <div class="modal-gmb-photo__footer__content__second-row__block">
              <v-icon class="modal-gmb-photo__footer__content__second-row__block__icon">
                {{ icons.mdiCardAccountDetails }}
              </v-icon>
              {{ type }}
            </div>
          </div>
          <div class="modal-gmb-photo__footer__content__third-row">
            <div class="modal-gmb-photo__footer__content__third-row__block">
              <v-icon class="modal-gmb-photo__footer__content__third-row__block__icon">
                {{ icons.mdiImageSizeSelectLarge }}
              </v-icon>
              {{
                $t('modal.gmbPhoto.footer.size', {
                  width: photo.dimensions.heightPixels,
                  height: photo.dimensions.widthPixels,
                })
              }}
            </div>
            <div class="modal-gmb-photo__footer__content__third-row__block">
              <v-icon class="modal-gmb-photo__footer__content__third-row__block__icon">
                {{ icons.mdiFolderInformation }}
              </v-icon>
              {{ photo.mediaFormat }}
            </div>
          </div>
          <div v-if="type === 'owner'" class="modal-gmb-photo__footer__content__fourth-row">
            <div class="modal-gmb-photo__footer__content__fourth-row__block">
              <v-icon class="modal-gmb-photo__footer__content__fourth-row__block__icon">
                {{ icons.mdiTag }}
              </v-icon>
              {{ $t(`locationsGmb.id.photos.section.categories.${photo.locationAssociation.category}`) }}
            </div>
          </div>
        </div>
        <ui-button
          class="modal-gmb-photo__footer__button"
          :label="$t('common.button.cancel')"
          button="secondary"
          @click="closed"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import { timeFromNow } from '@/utils/hours.util'
import { formatedDate } from '@/utils/date.util'
import { mdiImageSizeSelectLarge, mdiEye, mdiFolderInformation, mdiCardAccountDetails, mdiTag } from '@mdi/js'

export default {
  name: 'ModalGmbPhoto',
  components: {
    UiButton,
  },
  data() {
    return {
      formatedDate,
      icons: {
        mdiImageSizeSelectLarge,
        mdiEye,
        mdiFolderInformation,
        mdiCardAccountDetails,
        mdiTag,
      },
    }
  },
  props: {
    photo: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    deletePhoto() {
      this.$emit('deletePhoto', this.photo)
      this.closed()
    },
    closed() {
      this.$emit('closed')
    },
    formatedTime(time) {
      return timeFromNow(time, true)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-gmb-photo {
  &__top {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    justify-content: flex-end;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0) 100%);
    padding: 8px;

    &__link,
    &__delete,
    &__details,
    &__report {
      color: $generic-color-text-alt;
    }

    &__delete,
    &__report,
    &__details {
      margin-left: $gutter-mobile / 2;
    }
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -$gutter-mobile;

    @media (min-width: $screen-sm) {
      margin: -$gutter-tablet;
    }

    &__img {
      max-width: 100%;
    }
  }

  &__popover {
    &__container {
      display: flex;
      flex-direction: column;
      padding: $gutter-mobile;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
      }

      &__text {
        margin-bottom: 1rem;
      }

      &__buttons {
        &__delete {
          color: map-get($generic-color-variants, 'error');
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__button {
      margin: 0 auto;
    }

    &__content {
      &__first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.12);
        padding: 8px;
      }

      &__second-row,
      &__third-row,
      &__fourth-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 12px;
        padding: 0 8px;

        &__block {
          display: flex;
          flex-direction: row;
          align-items: center;

          &__icon {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
