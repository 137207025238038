<template>
  <screen-grid class="location-gmb-extra">
    <screen-card
      :title="$t('locationsGmb.id.extras.section.extraData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.extras.section.extraData.label.googleAdsPhone')"
          icon="phone"
          :has-data="hasGoogleAdsPhone"
          :data="hasGoogleAdsPhone ? currentLocation.adWordsLocationExtensions.adPhone : ''"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('adWordsLocationExtensions.adPhone', 'text', 'input')"
        />
        <screen-block
          :title="$t('locationsGmb.id.extras.section.extraData.label.languageCode')"
          icon="language"
          :has-data="typeof currentLocation.languageCode === 'string'"
          :data="currentLocation.languageCode"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-block
          :title="$t('locationsGmb.id.extras.section.extraData.label.locations')"
          icon="article"
          :has-data="currentLocationMapping.length > 0"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="true"
          @click="
            editSimpleField(
              'locationsMapping',
              'text',
              'list',
              false,
              null,
              locationsSearch,
              {
                label: 'name',
                trackBy: 'id',
              },
              true,
              false
            )
          "
        >
          <template v-slot:data>
            <div class="location-gmb-extra__locations">
              <span
                class="location-gmb-extra__locations__item"
                v-for="(location, idx) in currentLocationMapping"
                :key="idx"
              >
                {{ location.name }}
              </span>
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locationsGmb.id.extras.section.locationIds.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.extras.section.locationIds.label.placeId')"
          icon="attribut"
          :has-data="(hasLocationKey && typeof currentLocationPlaceId === 'string') || false"
          :data="currentLocationPlaceId"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
        />
      </template>
    </screen-card>
    <screen-card align="center" ratio="1-3" v-for="(value, key) in currentLocation.metadata" :key="key">
      <template v-slot:body>
        <screen-block
          :title="$t(`locationsGmb.id.extras.section.metadata.label.${key}`)"
          align="center"
          :has-data="true"
          :class="{
            'location-gmb-extra__status--active': value,
            'location-gmb-extra__status--inactive': !value,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div class="location-gmb-extra__status__icon backoffice-icons" v-else>check</div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div
              class="location-gmb-extra__status__state"
              :class="{ 'location-gmb-extra__status__state--row': checkIsLink(value) }"
              v-else
            >
              <template v-if="value">
                {{ $t('common.button.yes') }}
                <div v-if="typeof value === 'string'">
                  <v-btn
                    icon
                    :href="value"
                    v-if="checkIsLink(value)"
                    class="location-gmb-extra__status__state__link"
                    target="_blank"
                  >
                    <v-icon> {{ icons.mdiOpenInNew }}</v-icon>
                  </v-btn>
                  <div v-else class="location-gmb-extra__status__state__text">
                    {{ value }}
                  </div>
                </div>
              </template>
              <template v-else>
                {{ $t('common.button.no') }}
              </template>
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { checkIsLink } from '@/utils/utilities.util'
import { mdiOpenInNew } from '@mdi/js'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'

export default {
  name: 'LocationExtra',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    SkeletonIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    locationsSearch: {
      type: Array,
      required: true,
    },
    currentLocationMapping: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkIsLink,
      icons: {
        mdiOpenInNew,
      },
    }
  },
  computed: {
    currentLocationPlaceId() {
      return this.currentLocation.metadata?.placeId
    },
    hasGoogleAdsPhone() {
      if (this.currentLocation?.adWordsLocationExtensions?.adPhone) {
        return true
      }
      return false
    },
    hasLocationKey() {
      return this.currentLocation?.metadata && Object.keys(this.currentLocation.metadata).length > 0
    },
  },
  methods: {
    editSimpleField(
      objKey,
      type,
      mode,
      required = false,
      number = null,
      options = [],
      dropdown = {},
      mappingEmit = false,
      taggable = true
    ) {
      this.$emit(mappingEmit ? 'editLocationMapping' : 'editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
          taggable,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.location-gmb-extra {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-gmb-extra__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-extra__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-extra__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-gmb-extra__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-extra__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-extra__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__state {
      margin-top: 2px;

      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__link {
        transition: all $transition-duration-default $transition-effect-default;
        margin-left: 0.5rem;

        &:hover {
          color: var(--product-color);
        }
      }

      &__text {
        word-break: break-all;
        color: var(--text-color-alt);
        font-size: 0.82rem;
      }

      .location-gmb-extra__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-extra__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-extra__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-gmb-extra__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-extra__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-extra__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__button {
      margin-left: $gutter-mobile;
    }

    &__popover {
      min-width: 280px;

      @media (min-width: $screen-sm) {
        min-width: 340px;
      }

      &__choice {
        display: flex;
        align-items: center;
        padding: 0 $gutter-mobile;
        height: 48px;

        @media (min-width: $screen-sm) {
          padding: 0 $gutter-tablet;
        }

        &:hover {
          background-color: var(--bg-color-hover);
          cursor: pointer;
        }

        &:first-child {
          border-top-left-radius: $radius-default;
          border-top-right-radius: $radius-default;
        }

        &:last-child {
          border-bottom-left-radius: $radius-default;
          border-bottom-right-radius: $radius-default;
        }

        &__icon {
          margin-right: $gutter-mobile;
        }
      }
    }
  }

  &__locations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    &__item {
      border-radius: 50px;
      background-color: map-get($generic-color-variants, 'data');
      padding: 2px 8px;
      color: $white;
      font-size: $font-size-sm;
    }
  }
}
</style>
