<template>
  <modal
    name="modal-gmb-category"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating || disabledApplyButton"
    :applyLabel="$t('modal.gmbCategory.apply')"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <div class="modal-gmb__title">
        {{ parameters.label }}
      </div>
      <div class="modal-gmb__block" v-for="(attr, idx) in parameters.options" :key="`attr-${idx}`">
        <ui-checkbox
          class="modal-gmb__block__checkbox"
          :id="`checkbox-${idx}`"
          :input-value="true"
          @input="setCategory($event, attr)"
        />
        <div
          @click="attr.freeFormServiceItem === false ? false : editService(attr)"
          :class="{ 'modal-gmb__block__content--pointer': attr.freeFormServiceItem }"
          class="modal-gmb__block__content"
        >
          {{ getServiceLabel(attr) }}
          <span
            :class="
              attr.freeFormServiceItem
                ? 'modal-gmb__block__content__badge--free'
                : 'modal-gmb__block__content__badge--google'
            "
            class="modal-gmb__block__content__badge"
          >
            {{ attr.freeFormServiceItem ? $t('modal.gmbCategory.free') : $t('modal.gmbCategory.google') }}
          </span>
        </div>
      </div>

      <div class="modal-gmb__services" :class="{ 'modal-gmb__services--dark': $vuetify.theme.dark }">
        <div class="modal-gmb__services__header">
          <div class="modal-gmb__services__header__new">
            {{ $t(editionMod ? 'modal.gmbCategory.edit' : 'modal.gmbCategory.newService') }}
          </div>
          <!-- <ui-switch
            v-if="!editionMod"
            class="modal-gmb__services__header__custom"
            id="switch-custom"
            :reverse="true"
            :input-value="customMod"
            v-model="customMod"
            @input="customModTrigger"
          >
            {{ $t('modal.gmbCategory.perso') }}
          </ui-switch> -->
          <!-- v-else for button -->
          <ui-button
            @click="customModTrigger"
            class="modal-gmb__services__header__close"
            button="secondary"
            :label="$t('common.button.close')"
            :icon-only="true"
            icon="close"
          />
        </div>
        <div class="modal-gmb__services__create">
          <ui-input
            v-if="customMod || editionMod"
            class="modal-gmb__services__create__input"
            v-model="initCreateService.label.displayName"
            :id="initCreateService.label.displayName"
            :label="$t('modal.gmbCategory.inputLabel')"
          />
          <ui-dropdown
            v-else-if="!editionMod"
            v-model="googleService"
            class="modal-gmb__services__create__dropdown"
            :no-absolute="true"
            label="displayName"
            :options="googleServicesWithoutSelect"
            :placeholder="$t('modal.gmbCategory.dropdownLabel')"
            :track-by="googleServiceTrackBy"
          />
          <div class="modal-gmb__services__create__bottom">
            <div class="modal-gmb__services__create__bottom__row">
              <ui-button
                :disabled="disabledCreate"
                @click="createService(JSON.parse(JSON.stringify(initCreateService)), editionMod)"
                :label="$t(editionMod ? 'modal.gmbCategory.save' : 'modal.gmbCategory.create')"
                button="secondary"
                variant="success"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UiCheckbox from '@/components/UI/Checkbox.vue'
// import UiSwitch from '@/components/UI/Switch.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'

const initCreateService = () => ({
  id: '',
  freeFormServiceItem: true,
  label: {
    displayName: '',
  },
  name: '',
})

export default {
  name: 'ModalGmbCategory',
  components: {
    UiCheckbox,
    // UiSwitch,
    UiDropdown,
    UiInput,
    UiButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: [String, Object, Array, Number],
      required: true,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        options: [],
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      newServiceMod: 'free',
      initCreateService: initCreateService(),
      customMod: false,
      editionMod: false,
    }
  },
  created() {
    const regionCode = this.currentLocation.storefrontAddress.regionCode
    const locale = this.$i18n.locale
    this.loadServices({ regionCode, locale, categoryId: this.parameters.categoryId })
  },
  mounted() {
    this.modelData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    ...mapState({
      googleServices: state => state.gmb.services,
    }),
    googleServiceTrackBy() {
      if (!this.googleServicesWithoutSelect[0]?.serviceTypeId) {
        return 'name'
      }
      return 'serviceTypeId'
    },
    googleServicesWithoutSelect() {
      const googleIdSelect = this.modelData.map(service => service.serviceTypeId)
      return this.googleServices.filter(googleService => {
        if (!googleIdSelect.includes(googleService.serviceTypeId)) {
          return googleService
        }
      })
    },
    googleService: {
      get() {
        return this.initCreateService.serviceTypeId || this.initCreateService.name ? this.initCreateService : null
      },
      set(service) {
        service.freeFormServiceItem = false
        this.initCreateService = service
      },
    },
    disabledApplyButton() {
      return !!(this.initCreateService.label?.displayName || this.initCreateService.displayName)
    },
    disabledCreate() {
      return !this.customMod && !this.initCreateService?.serviceTypeId
    },
  },
  methods: {
    ...mapActions({
      loadServices: 'gmb/loadServices',
    }),
    save() {
      this.$emit('save', {
        currentServices: this.modelData,
        categoryId: this.parameters.name || this.parameters.categoryId,
        displayName: this.parameters.label,
      })
    },
    closed() {
      this.$emit('closed')
    },
    getServiceLabel(service) {
      return service.label?.displayName || service.displayName
    },
    customModTrigger() {
      this.editionMod = false
      this.initCreateService = initCreateService()
    },
    editService(service) {
      this.customMod = true
      this.editionMod = true
      this.initCreateService = JSON.parse(JSON.stringify(service))
    },
    getOptions(attr) {
      return attr.valueMetadata
    },
    setCategory(isActive, service) {
      if (isActive) {
        this.modelData.push(JSON.parse(JSON.stringify(service)))
      } else {
        this.modelData.splice(this.serviceIsActive(service), 1)
      }
    },
    createService(service, edition = false) {
      if (edition) {
        const services = JSON.parse(JSON.stringify(this.parameters.options))
        this.modelData.splice(this.serviceIsActive(service), 1, service)
        services[this.serviceIsActive(service, this.parameters.options)].label.displayName = service.label.displayName
        this.$emit('updateOptions', services)
      } else {
        delete service.temporyLabel
        service.id = `${Math.random().toString(36).slice(2)}}`
        this.$emit('updateOptions', [...this.parameters.options, service])
        this.setCategory(true, service)
      }
      this.initCreateService = initCreateService()
      this.editionMod = false
    },
    serviceIsActive(service, searchArray = this.modelData) {
      return searchArray.findIndex(serviceData => serviceData.id === service.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-gmb {
  &__title {
    margin-bottom: 16px;
    font-weight: 600;
  }

  &__block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__checkbox {
      &--offset {
        margin-top: 20px;
      }
    }

    &__input {
      flex: 1;
    }

    &__content {
      display: flex;
      flex: 100%;
      align-items: center;
      justify-content: space-between;

      &--pointer {
        cursor: pointer;
      }

      &__badge {
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 2.5px 5px;
        color: $white;
        font-size: $font-size-sm;

        &--free {
          background-color: $blue-cerulean;
        }
        &--google {
          background-color: $green-salem;
        }
      }
    }
  }

  &__services {
    background-color: $grey-good-table-sep;
    padding: $gutter-mobile;

    &--dark {
      background-color: $interface-dark-grade-1-color;
    }

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__new {
        margin-right: 12px;
      }
    }

    &__create {
      &__input {
        margin: $gutter-mobile 0;
        padding-top: 0;
      }

      &__dropdown {
        margin: $gutter-mobile 0;
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__row {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (min-width: $screen-sm) {
            flex-direction: row;
          }

          &__switch {
            width: max-content;
            -webkit-margin-bottom-collapse: 10px;

            @media (min-width: $screen-sm) {
              -webkit-margin-bottom-collapse: 0;
            }
          }
        }
      }
    }
  }
}
</style>
